import React from 'react';
import * as XLSX from 'xlsx';
import { formatDate, formatTime } from './formatTimeDate';

const SaveToFile = ({ data, fecha }) => {
  const descargarExcel = () => {
    // Convertir los datos, excluyendo propiedades no deseadas y renombrando oSocial a Obra Social
    const datosPreparados = data.map(({ pkid, oSocial, ...item }) => ({
      Hora: formatTime(item.hora),
      Nombre: item.nombre,
      'Obra Social': oSocial,
    }));

    // Crear una hoja con los datos preparados
    const ws = XLSX.utils.json_to_sheet([], {skipHeader: true}); // Inicia con hoja vacía para configurar manualmente
    const encabezado = ['Hora', 'Nombre', 'Obra Social'];
    XLSX.utils.sheet_add_aoa(ws, [[`Agenda del ${formatDate(fecha)}`]], {origin: 'A1'}); // Añadir título
    XLSX.utils.sheet_add_aoa(ws, [encabezado], {origin: 'A4'}); // Añadir encabezados de columnas
    XLSX.utils.sheet_add_json(ws, datosPreparados, {origin: 'A5', skipHeader: true}); // Añadir datos
    
    // Ajustar el ancho de las columnas basado en el contenido
    const colWidths = encabezado.map((_, i) => {
      return {
        wch: Math.max(...datosPreparados.map(row => row[encabezado[i]]?.toString().length || 0), encabezado[i].length) + 2
      };
    });
    ws['!cols'] = colWidths;

    // Crear libro y añadir la hoja
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Agenda');

    // Generar y descargar el archivo Excel
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `Agenda del ${formatDate(fecha)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Conversión de string a ArrayBuffer
  function s2ab(s) {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buffer;
  }

  return <button onClick={descargarExcel}>Descargar a Excel</button>;
};

export default SaveToFile;