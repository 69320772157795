const liObrasSociales = [
  { value: "", label: "Obra Social" },
  { value: "OSDE", label: "OSDE" },
  { value: "Union Personal", label: "UP" },
  { value: "Union Personal PMO", label: "UP PMO" },
  { value: "Accord", label: "Accord" },
  { value: "IOMA", label: "IOMA" },
  { value: "Sancor", label: "Sancor" },
  { value: "Medife", label: "Medife" },
  { value: "Medicus", label: "Medicus" },
  { value: "Osmecon", label: "Osmecon" },
  { value: "Particular", label: "Particular" },
  { value: "Banco Provincia", label: "Banco Provinca" },
  { value: "Poder Judicial", label: "Poder Judicial" },
  { value: "Bristol", label: "Bristol" },
];


export default liObrasSociales;