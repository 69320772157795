import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import './Menu.css';
import { useAuth0 } from '@auth0/auth0-react'; 


function Menu() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const shouldRenderMenu = location.pathname !== '/ListaPacientes';
  const closeTimer = useRef();
  const {loginWithRedirect} = useAuth0();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scheduleCloseMenu = () => {
    closeTimer.current = setTimeout(() => {
      setIsOpen(false);
    }, 500); // Retraso de 2 segundos antes de cerrar el menú
  };

  const cancelScheduledClose = () => {
    clearTimeout(closeTimer.current);
  };

  return (
    shouldRenderMenu && (
      <div className="menu-container" onMouseLeave={scheduleCloseMenu} onMouseEnter={cancelScheduledClose}>
        <button className="menu-button" onClick={toggleMenu}>
          Menu
        </button>
        <ul className={`menu-items ${isOpen ? "open" : ""}`}>
          <li><Link to="/Admin">Administrador</Link></li> 
          <li><Link to="/Agenda">Agenda</Link></li>
          <li><Link to="/ListaPacientes">Lista Pacientes</Link></li>
          <button onClick={() => loginWithRedirect()}>INGRESAR</button>
        </ul>
      </div>
    )
  );
}
export default Menu;
