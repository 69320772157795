import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ListaPacientes.css";

const ListadoPacientes = () => {
  const [listaPacientes, setListaPacientes] = useState([]);
  const url = 'https://doctorcito.online';
  //const url = "http://localhost:5000";

  // Función para obtener la lista de nombres desde el servidor
  const obtenerListaPacientes = () => {
    axios
      .get(`${url}/names`)
      .then((response) => {
        setListaPacientes(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener la lista de nombres:", error);
      });
  };

  // Utilizamos useEffect para ejecutar la función una vez al cargar el componente
  useEffect(() => {
    obtenerListaPacientes();

    // Establecer un intervalo para actualizar la lista cada 5 segundos (por ejemplo)
    const intervalo = setInterval(() => {
      obtenerListaPacientes();
    }, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo al desmontar el componente para evitar fugas de memoria
    return () => clearInterval(intervalo);
  }, []);
  return (
     
     
       
        <ol className="" >
          {listaPacientes.map((name, index) => (
            <li key={index} className={index === 0 ? "brillo" : ""}>{name}</li>
          ))}
        </ol>
       
    
  );
};

export default ListadoPacientes;
